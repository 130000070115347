/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Text, Button, Divider } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-1f8c1a4 --full --parallax pb--60 pt--60" name={"0mt0f3de7iob"} parallax={true} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32042/93add6fe25fe4451ac8c2622b4c18bce_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32042/93add6fe25fe4451ac8c2622b4c18bce_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32042/93add6fe25fe4451ac8c2622b4c18bce_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32042/93add6fe25fe4451ac8c2622b4c18bce_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32042/93add6fe25fe4451ac8c2622b4c18bce_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32042/93add6fe25fe4451ac8c2622b4c18bce_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32042/93add6fe25fe4451ac8c2622b4c18bce_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32042/93add6fe25fe4451ac8c2622b4c18bce_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--102 title-box--invert" content={"Modrý pavilon&nbsp;<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--50" name={"sluzby"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/32042/31606954c85748309da2badb4fec5824_e=0x0x1447x1447_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/32042/31606954c85748309da2badb4fec5824_e=0x0x1447x1447_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/31606954c85748309da2badb4fec5824_e=0x0x1447x1447_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/31606954c85748309da2badb4fec5824_e=0x0x1447x1447_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/31606954c85748309da2badb4fec5824_e=0x0x1447x1447_s=1400x_.jpg 1400w"} position={null}>
              </Image>

              <Title className="title-box" content={"Zažít Krč jinak"}>
              </Title>

              <Text className="text-box" content={"V rámci komunity na krčském sídlišti se pod hlavičkou Zažít Krč jinak, konají v  Modrém pavilonu a jeho okolí různé sousedské slavnosti, zdobení vánočního stromečku, swapy, přednášky a mnoho dalších. Největší z těchto akcí je zářijové Zažít Krč jinak, kdy se přilehlá louka promění na jeden velký piknik s řadou workshopů a pěší zónu zaplaví nepřeberné množství stánků a atrakcí pro malé i velké."}>
              </Text>

              <Button className="btn-box" url={"https://www.facebook.com/ZazitKrcJinakHurbanova"} href={"https://www.facebook.com/ZazitKrcJinakHurbanova"} content={"Facebook&nbsp;"}>
              </Button>

              <Divider >
              </Divider>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/32042/dff0e05f68b94c92a6285758ddfe73c4_e=0x0x1448x2048_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/32042/dff0e05f68b94c92a6285758ddfe73c4_e=0x0x1448x2048_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/dff0e05f68b94c92a6285758ddfe73c4_e=0x0x1448x2048_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/dff0e05f68b94c92a6285758ddfe73c4_e=0x0x1448x2048_s=860x_.jpg 860w, https://cdn.swbpg.com/t/32042/dff0e05f68b94c92a6285758ddfe73c4_e=0x0x1448x2048_s=1400x_.jpg 1400w"} position={null}>
              </Image>

              <Title className="title-box" content={"Očekávané akce"}>
              </Title>

              <Text className="text-box" content={"Odtajnili jsme letošní plakátek a od této chvíle se můžete těšit na info, co všechno pro Vás na září chystáme. Takže si napište do kalendářů 14. 9. od 14h Zažít Krč jinak 2023 a sledujte naší událost."}>
              </Text>

              <Button className="btn-box" url={"https://fb.me/e/348iMZ0O6"} href={"https://fb.me/e/348iMZ0O6"} content={"Chci vědět víc&nbsp;"}>
              </Button>

              <Divider >
              </Divider>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/32042/f6b4493b7020416cbeb73bb6d71b170f_e=0x0x1200x1107_s=660x_.jpg"} sizes={"100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/32042/f6b4493b7020416cbeb73bb6d71b170f_e=0x0x1200x1107_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32042/f6b4493b7020416cbeb73bb6d71b170f_e=0x0x1200x1107_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32042/f6b4493b7020416cbeb73bb6d71b170f_e=0x0x1200x1107_s=860x_.jpg 860w"} position={null}>
              </Image>

              <Title className="title-box" content={"Unlimited Freedom"}>
              </Title>

              <Text className="text-box" content={"Ritchie podnikl v září 2021 nelegální cestu do oblasti černobylské jaderné elektrárny. Svůj příběh živě popisoval na sociálních sítích desítkám tisíc fanoušků, kteří tak měli možnost nahlédnout do zapovězené Černobylské zóny, kam se běžný smrtelník nedostane. Publikace zachycuje jeho autentické popisy z putování krajinou skrze ukrajinské lesy až do centra Zóny."}>
              </Text>

              <Button className="btn-box" url={"https://www.epocha.cz/kniha/richard-smid-cernobylsky-denik-prvni-vydani-vazana/"} href={"https://www.epocha.cz/kniha/richard-smid-cernobylsky-denik-prvni-vydani-vazana/"} content={"Kniha&nbsp;"}>
              </Button>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"paticka"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box" content={"Modrý pavilon"}>
              </Title>

              <Text className="text-box" content={"+420 739 090 844<br>ocmodrypavilon@seznam.cz<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}